<template>
    <section class="content">
        <div class="row">
            <div class="col-12">

                <div class="row">
                    <!-- Column -->
                    <div>
                        <label>Wallet Balance <button v-if="isRoleAllowedDelete" class="btn btn-xs btn-primary" data-toggle="modal" data-target="#topup-wallet">Top Up</button></label>
                        <p>₦ {{ walletHistory && walletHistory.wallet_balance ? formatBalance(walletHistory.wallet_balance) : '0.00' }}</p>
                        <TopupWallet :customerData="customerData"/>
                    </div>
                </div>
                
                <div class="col-12">
                    <form @submit.prevent="onSubmit">
                        <div class="box bg-pale-secondary box-outline-dark">
                            <div class="box-body">
                                <div class="contact-page-aside">
                                    <ul class="list-style-none list-inline font-size-16">
                                        <li>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <input v-model="transactionID" type="text" name="text" class="form-control" placeholder="Transaction ID">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group">
                                                <select v-model="type" class="form-control">
                                                    <option value="">All Transaction Type</option>
                                                    <option value="pending">Credit</option>
                                                    <option value="unpaid">Debit</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li class="box-label float-right"><button class="btn btn-primary text-white mt-10" @click="loadCustomerWalletHistory">Search</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- /. box -->
                    </form>
                </div>
                <!-- /.col -->

                <div class="box box-body px-0">						
                    <div class="table-responsive">
                        <table id="tickets" class="table mt-0 table-hover no-wrap table-bordered" data-page-size="10">
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>For</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="wallet in walletHistory.history" :key="wallet.id">
                                    <td>{{ wallet.transaction ? wallet.transaction.transactionID : '' }}</td>
                                    <td><span :class="walletTypeClass(wallet)">{{ wallet.type }}</span></td>
                                    <td>{{ wallet.amount }}</td>
                                    <td>{{ wallet.for }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2">

                                    </td>
                                    <td colspan="2">
                                        <div class="text-right">
                                            <ul class="pagination"> </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>


<script>
import { repository } from '../../presenter';
import { mapGetters } from 'vuex';
import TopupWallet from '../../components/Customer/TopupWallet';
export default {
    components: {
        TopupWallet,
    },
    props: ['customerData'],
    mounted() {
        const datatable = document.createElement('script')
        datatable.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/datatable/datatables.min.js`)
        document.head.appendChild(datatable)
        datatable.onload = function(){
            const dataTable2JS = document.createElement('script')
            dataTable2JS.setAttribute('src', `${this.publicPath}assets/js/pages/data-table.js`)
            document.head.appendChild(dataTable2JS)
        }

        this.loadCustomerWalletHistory()
        // this.loadCustomerOrders()
    },
    data(){
        return{
            publicPath: process.env.BASE_URL,
            customer:this.customerData,
            transactionID: '',
            type: '',
        }
    },
    watch:{
        walletHistory: function (val) {
            console.log('wallet history is ', val)
            console.log("wallt bala is ", this.walletHistory.wallet_balance)
        }
    },
    computed: {
        ...mapGetters('transaction', {
            walletHistory: 'retrieveCustomerWalletHistory',
        }),
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },
    methods: {
        
        loadCustomerWalletHistory() {
            const data = {
                userID: this.customerData.id,
                transactionID: this.transactionID,
                type: this.type,
            }
            repository.transaction.loadCustomerWalletHistory(data);
        },
        formatBalance(balance) {
            return balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        walletTypeClass(wallet){
            switch (wallet.type){
                case 'credit':
                    return 'label label-primary';
                case 'debit':
                    return 'label label-danger';
             
            }
        }
    }
}
</script>

